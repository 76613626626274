<template>
  <ion-page>
    <account-header />
    <ion-content id="content">
      <account-info />
      <card-invoice-account :key="new Date()" />
      <extra-info />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import AccountHeader from './components/AccountHeader.vue';
import AccountInfo from './components/AccountInfo.vue';
import ExtraInfo from './components/ExtraInfo.vue';
import CardInvoiceAccount from './components/CardInvoiceAccount.vue';
import { ACTIONS } from '@/modules/sale/store/account/actions';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('sale/account');

export default defineComponent({
  name: 'Account',
  components: {
    AccountHeader,
    AccountInfo,
    ExtraInfo,
    CardInvoiceAccount
  },
  async ionViewDidEnter() {
    await this[ACTIONS.GET_CURRENT_USER]();
  },
  methods: {
    ...mapActions([ACTIONS.GET_CURRENT_USER])
  }
});
</script>
