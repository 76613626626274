<template>
  <div>
    <!-- account info -->
    <div class="px-4" v-if="user">
      <!-- avatar section -->
      <div class="avatar">
        <ion-avatar class="avatar-image">
          <td-image :image="user.image" :imagePath="IMAGE_PATH.USER"></td-image>
          <div class="avatar-border">&nbsp;</div>
        </ion-avatar>
      </div>

      <div class="d-flex justify-center align-center flex-column avatar-info">
        <div class="full-name">
          {{
            user.first_name
              ? user.first_name + ' ' + user.last_name
              : memory?.first_name + ' ' + memory?.last_name
          }}
        </div>
        <div class="first-name text-capitalize">
          {{ user.type ? user.type.name : memory?.type ? memory?.type.name : '-' }}
        </div>
      </div>
      <!-- end avatar section -->

      <!-- User info -->
      <div class="d-flex flex-column">
        <div class="d-flex mt-3">
          <div class="col-auto fs-3 text-grey text-light">{{ $t('phone') }}</div>
          <div class="col ml-auto text-right text-black ellipsis fs-3">
            {{ user.mobile ? user.mobile : memory?.mobile }}
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="col-auto fs-3 text-grey text-light">{{ $t('email') }}</div>
          <div class="col ml-auto text-right text-black ellipsis fs-3">
            {{ user.email ? user.email : memory?.email }}
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="col-auto fs-3 text-grey text-light">{{ $t('gender') }}</div>
          <div class="col ml-auto text-right text-black ellipsis fs-3">
            {{
              user.gender ? getGenderName(user.gender) : memory?.gender ? getGenderName(memory?.gender) : '-'
            }}
          </div>
        </div>
      </div>
      <!-- end User info -->

      <!-- Button List -->
      <div>
        <ion-button
          class="text-white w-100 mt-4 text-initial btn-account"
          router-link="/sale/update-profile"
          color="primary"
        >
          {{ $t('update_profile') }}
        </ion-button>
        <ion-button
          class="text-white w-100 text-initial btn-account mt-2"
          router-link="/sale/customers"
          color="primary"
        >
          {{ $t('accountPage.manage_companines') }}
        </ion-button>
      </div>
      <!-- Button List -->
    </div>
    <!-- end account info -->
  </div>
</template>

<script>
import { DEFAULT_AVATAR, IMAGE_PATH } from '@/modules/sale/constants/index';
import { getGenderName } from '@/modules/sale/services/libs/helper';
import { ACTIONS } from '@/modules/sale/store/account/actions';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/account');

export default defineComponent({
  name: 'AccountInfo',

  setup() {
    const memory = ref(null);
    return {
      getGenderName,
      IMAGE_PATH,
      DEFAULT_AVATAR,
      memory
    };
  },
  computed: {
    ...mapGetters(['user', 'cropImg'])
  },
  async mounted() {
    await this[ACTIONS.GET_CURRENT_USER]();
  },
  watch: {
    async cropImg() {
      await this[ACTIONS.GET_CURRENT_USER]();
    },
    user() {
      if (this.user?.first_name == undefined || this.user?.last_name == undefined) {
        return;
      } else {
        this.memory = this.user;
      }
    }
  },
  methods: {
    ...mapActions([ACTIONS.GET_CURRENT_USER])
  }
});
</script>

<style scoped>
.text-light {
  color: #9e9e9e;
}
.banner {
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 14px;
}
.banner p {
  padding: 8px;
  margin-bottom: 0;
  margin-top: 0;
}
.avatar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
}

.avatar-image {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  position: relative;
}

.avatar-border {
  border-radius: 50%;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  border: 3px solid var(--ion-color-primary);
}

.avatar-info .full-name {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
}

.avatar-info .first-name {
  color: #9e9e9e;
  font-size: 12px;
}

.btn-account {
  height: 2.4em;
}

.btn-account::part(native) {
  font-size: 14px;
  border-radius: 10px;
}
</style>
